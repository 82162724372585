import React from "react"

import SEO from "../../components/seo"
import CenterBreadCrumbs from "../../components/centerBreadCrumbs"

const AboutUs = () => {
  return (
    <>
      <SEO title="Kidd Inn - About Us" />

      <CenterBreadCrumbs
        centerName="Kidd Inn"
        centerUrl="/kiddinn/"
        subPageName="About Us"
      />

      <div className="container-fluid horizontal-section-container clearfix">
        <div className="row">
          <div className="col-sm-12">
            <div
              id="main-page-content"
              className="section-container main-page-content clearfix"
            >
              <div className="section-content">
                <h1 className="page_title">About Us</h1>

                <h4>Kidd Inn Early Learning Centre Ltd</h4>

                <div id="content">
                  <p>
                    Kidd Inn has recently extended its grounds to embrace our
                    under 2's centre Just Kidd Inn. We have created a unique
                    environment where our Infants, Toddlers &amp; Preschoolers
                    have there own individual edifice, where these areas can be
                    shared &amp; manipulated to ensure our needs as a whole
                    centre are meet cohesively.&nbsp;
                  </p>

                  <p>
                    Our Babies room is called the Morepork room, as infants can
                    be night owls. The curriculum is based around genuine
                    respectful relationships &amp; practices. We endeavour to
                    structure our day around the needs of each child, to ensure
                    this we have incorporated a Key Caregiver approach., where
                    each educator has a group of children and they complete all
                    of their Key Care Moments eg nappy changes, lunch, bottles
                    and being put to sleep.
                  </p>
                  <p>
                    The Toddler room is called the Tuis, as they are so busy.
                    The toddlers are in a purpose built building, where the
                    unique needs and urges of this age group are meet and
                    fostered. We have extended our Key Caregiver approach with
                    the age group to ensure continuity at a high level of
                    quality care.
                  </p>
                  <p>
                    The Preschool area is called the Kereru room, as they love
                    to explore and investigate. We also have Te Kete Aronui,
                    where a small group of our older 4 year olds further develop
                    the skills required for the transition to school. The
                    preschool area is designed to foster optimal learning at the
                    children's pace. We have specific environments that provide
                    opportunities for exploration, where educators are present
                    to scaffold and build upon these experiences.&nbsp;
                  </p>

                  <p>
                    Kidd Inn provides a vast range of learning experiences based
                    upon the children’s current interests and enhanced through
                    the ingenuity of highly skilled and motivated
                    teachers.&nbsp; The environment is constantly under review
                    in order to ensure we are providing the children with
                    exciting new challenges, which intensifies the imagination.
                  </p>

                  <p>
                    Everyday is a new fun-filled day at Kidd Inn.&nbsp; We
                    believe while the children are playing and having fun they
                    are learning to become self-directed self-motivated
                    learners.&nbsp; We believe in warm responsive care that
                    creates respectful relationships, promoting self -esteem and
                    self worth.&nbsp; We promote a healthy relationship with the
                    planet by teaching sustainable practices, such as gardening,
                    composting and reducing waste, therefore installing good
                    practices for life within our children.
                  </p>

                  <p>
                    For further information, please ensure you read our
                    Philosophy, to ensure that our centre aligns with with your
                    family values, aspirations and goals.&nbsp;
                  </p>

                  <p>
                    <i>Testimonial from Kimberley White…..</i>
                  </p>

                  <p>
                    <i>
                      When we were looking to relocate from Sydney the first
                      task I completed was sorting out care for my two children.
                      It was important to us that the centre we chose was family
                      orientated, with an appreciation for the individual child,
                      caring staff that would treat our children as if they were
                      their own.
                    </i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUs
